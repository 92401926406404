@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body, label {
	font-family: 'Roboto', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
.test {
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.test::before {
	content: '';
	border-top: none;

    position: absolute;
    width: 0;
	height: 0;
	bottom: 100%;
	left: +30%;
	border: .6rem solid transparent;
	border-top: none;

	border-bottom-color: rgb(77 173 225 / var(--tw-bg-opacity));
}

#footerOut {
	width: 100%;
	height: 130px;
	overflow: hidden;
	position: relative;
}

#footerIn {
	width: 110%;
	height: 120%;
	border-radius: 40%;
	background: #FFF;
	position: absolute;
	left: -5%;
	top: -50px;
}

.accordion__content {
	opacity: 0;
}
.accordion__content.expanded {
	animation: fadeIn .5s ease-out forwards;
	transform-origin: top
}
.accordion__content.exiting {
	animation: fadeOut .4s ease-in forwards;
	transform-origin: top
}

@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
		transform: scaleY(0)
	}
	1% {
		display: block;
	}
	100% {
		display: block;
		opacity: 1;
		transform: scaleY(1)
	}
}

@keyframes fadeOut {
	0% {
		display: block;
		opacity: 1;
		transform: scaleY(1)
	}
	99% {
		display: block;
	}
	100% {
		display: none;
		opacity: 0;
		transform: scaleY(0)
	}
}

}